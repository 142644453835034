import React, { memo, useEffect, useState } from 'react'
import { NewsTabsNavigation } from '@components'
import { Link, navigate } from 'gatsby'
import { Podcasts, News } from '@types'
import { formatDate } from '@utils'
import { useQuery } from '@apollo/client'
import { podcasts as graphqlPodcasts, news as graphqlNews } from '@graphql'
import { useInView } from 'react-intersection-observer'

import styles from './news-view-page.module.scss'

interface ResearchProps {
  id: string
}

const NewsViewPage: React.FC<ResearchProps> = ({ id }) => {
  const { data: { res: podcasts } = {}, loading: podcastsLoading } = useQuery<{ res: Podcasts }>(
    graphqlPodcasts.FetchPodcasts,
    {
      fetchPolicy: 'cache-first'
    }
  )

  const { data: { res: news } = {}, loading: newsLoading } = useQuery<{ res: News }>(graphqlNews.FetchAll, {
    fetchPolicy: 'cache-first',
    variables: { type: 'NEWS_FEATURED' }
  })

  const description = (summary: any, maxSymb?: number) => {
    return summary?.replace(/<(?:.|\n)*?>/gm, '').slice(0, maxSymb) + '...'
  }

  const [randomNews, setRandomNews] = useState(news?.[2])
  const [topRef, topRefInView] = useInView({
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.2
  })

  const [bottomRef, bottomRefInView] = useInView({
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.2
  })

  const currentNews = news?.find(item => item.id === id)

  let getRandom = (max: number) => {
    var rand = 0 + Math.random() * (max + 1 - 0 - 1)
    rand = Math.floor(rand)
    return rand
  }

  const setMainNews = () => {
    if (news) {
      setInterval(() => {
        const rand = getRandom(news.length)
        setRandomNews(news?.[rand])
      }, 15000)
    }
  }

  useEffect(() => {
    setRandomNews(news?.[2])
    setMainNews()
  }, [news])

  return (
    <main className={styles.news}>
      <NewsTabsNavigation id={id} />
      <div className={styles.container}>
        <div className={styles.newsList}>
          <div className={styles.card}>
            <div onClick={setMainNews} className={styles.cardJournal}>
              {currentNews?.sourceTitle}
            </div>

            <div className={styles.cardLine}>
              <div className={styles.cardLineInner}></div>
            </div>

            <h2 className={styles.cardTitle}>{currentNews?.title}</h2>
            {/* {currentNews && <div className={styles.cardDescription}>{description(currentNews?.summary, 3000)}</div>} */}
            {currentNews?.date && <div className={styles.cardDate}>{formatDate(currentNews?.date + '')}</div>}
            <div className={styles.cardHeaderHr}></div>
            {/* <div className={styles.cardImage} style={{ backgroundImage: `url(${currentNews?.image})` }}></div> */}
            {/* <div className={styles.cardImageDescription}>
              Mazatec psilocybin mushrooms ready for harvest. Johns Hopkins is opening a new center to study
              hallucinogenic compounds like psilocybin for a range of mental health problems.
            </div> */}
            <div className={styles.cardSummary}>
              <div className={styles.cardBy}>{currentNews?.author}</div>
              {currentNews && (
                <div
                  className={styles.cardSummaryDescription}
                  dangerouslySetInnerHTML={{ __html: `${currentNews?.summary}` }}
                />
              )}
              <div className={styles.goBack}>
                Back To:
                <Link className={styles.goBackLink} to="/news#list">
                  All News
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.recomendedNews}>
              <div onClick={() => navigate(`/news/${randomNews?.id}`)} className={styles.recomendedNewsMain}>
                <div
                  className={styles.recomendedNewsMainImage}
                  style={{ backgroundImage: `url(${randomNews?.image})` }}
                ></div>
                <div className={styles.recomendedNewsMainSource}>{randomNews?.sourceTitle}</div>
                <div className={styles.recomendedNewsMainTitle}>{randomNews?.title}</div>
                <div className={styles.recomendedNewsMainDescription}>
                  {randomNews && description(randomNews.summary, 150)}
                </div>
                <div className={styles.recomendedNewsMainFooter}>
                  {randomNews?.date && (
                    <div className={styles.recomendedNewsMainFooterDate}>{formatDate(randomNews?.date + '')}</div>
                  )}
                  <a href={randomNews?.link + ''} target="_blank" className={styles.recomendedNewsMainFooterLink}>
                    READ
                  </a>
                </div>
              </div>

              <div className={styles.recomendedNewsHeader}>RECOMMENDED</div>
              <div className={styles.recomendedNewsColumn}>
                <div>
                  {news?.map((item, index) => {
                    const { title, link, author, image, id } = item
                    return (
                      <div onClick={() => navigate(`/news/${id}`)} key={index} className={styles.cardItem}>
                        {index === 0 && <div ref={topRef}></div>}
                        {index === news.length - 1 && <div ref={bottomRef}></div>}
                        <div className={styles.cardItemImage} style={{ backgroundImage: `url(${image})` }}></div>
                        <div className={styles.cardItemSummary}>
                          <div className={styles.cardItemSummarySource}>{author}</div>
                          <div className={styles.cardItemSummaryDescription}>{title}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className={styles.newsScrollForMore}>
                  <svg
                    className={styles.newsScrollForMoreUp}
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 9L9 2L2 9"
                      stroke={topRefInView ? '#8C9CAD' : '#FFC469'}
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className={styles.newsScrollForMoreText}>Scroll For More</div>
                  <svg
                    className={styles.newsScrollForMoreDown}
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 2L9 9L16 2"
                      stroke={bottomRefInView ? '#8C9CAD' : '#FFC469'}
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className={styles.podcasts}>
              <div className={styles.podcastsHeader}>PODCASTS</div>
              <div className={styles.podcastsHeaderLinks}>
                <div className={styles.podcastsHearing}>WORTH HEARING</div>
                <Link to={'/news#media-listen'} className={styles.podcastsLinkAll}>
                  SEE ALL
                </Link>
              </div>
              {podcasts?.slice(0, 3).map((podcast, index) => {
                const { title, platform, avatar } = podcast
                return (
                  <Link key={index} to={'/news#media-listen'} className={styles.cardItem}>
                    <img
                      src={`https://palosanto.vc${avatar}`}
                      alt="CardImage"
                      height={150}
                      width={150}
                      className={styles.cardItemImage}
                    />
                    <div className={styles.cardItemSummary}>
                      <div className={styles.cardItemSummarySource}>{platform}</div>
                      <div className={styles.cardItemSummaryDescription}>{title}</div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default memo(NewsViewPage)
